.base {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(60,60,100,0.9);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.base img {
    max-width: 100%;
    margin-bottom: -5px;
    max-height: 90vh;
}

.base .modal {
    position: relative;
    overflow: scroll;
    max-height: 90vh;
    margin: 20px;
    box-shadow: 1px 1px 15px black;
}


.base .x-button {
    cursor: pointer;
    color: white;
    font-size: 40px;
    padding: 6px;
    position: absolute;
    top: 0;
    right: 0;
    background: #171572;
    transition: 300ms ease;
}

.base .x-button:hover {
    background: #0500FF;
}
