.base {
    color: white;
    text-align: center;
    width: 100%;
    background: #E32727;
    margin: 0;
    padding: 5px;
}

.base h1 {
    font-size: 1.6rem;
    font-weight: normal;
}
