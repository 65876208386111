.base {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    border-radius: 10px;
    position: relative;
    margin: 15px 0 15px 60px;
    padding: 30px;
    min-height: 125px;
}

.base .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FAFF00;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border-radius: 100%;
    position: absolute;
    left: -45px;
}

.base .icon-box svg {
    color: #171572;
    font-size: 2.5rem;
}

.base .title-box {
    display: flex;
    align-items: center;
    margin: 0 30px 0 45px;
    color: #171572;
}

.base .title-box span {
    margin-right: 15px;
}

.base .content-box {
    width: 63%;
    position: absolute;
    right: 3rem;
}

/* ---------- Media Queries ---------- */
@media only screen and (max-device-width: 768px) {

    .base {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: unset;
        margin: 60px 0;
        padding: 15px;
    }

    .base .icon-box {
        position: relative;
        top: -45px;
        left: unset;
    }

    .base .content-box {
        position: relative;
        width: 100%;
        right: unset;
    }
}
