@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,800;1,300&display=swap');


html {
  scroll-behavior: smooth;
}
.App {
  font-family: 'Open Sans', sans-serif;
  color: #616161;
}

