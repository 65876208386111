.base {
    overflow: hidden;
    background: #F3F3F3;
    border: 1px solid rgba(231, 231, 231, 0.3);
    box-sizing: border-box;
    min-width: 94%;
    border-radius: 10px;
    margin: 0 auto 30px;
    padding: 60px 45px;
    position: relative;
    /* TODO: Look into setting up animations later. */
    /*animation-name: grow;*/
    /*animation-duration: 1s;*/
    /*animation-iteration-count: 1;*/
    /*animation-timing-function: ease;*/
    /*animation-direction: normal;*/
    /*animation-fill-mode: both;*/

}

.base:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 30px solid transparent;
    border-bottom-color: #F3F3F3;
    border-top: 0;
    margin-left: -30px;
    margin-top: -30px;
}

@keyframes grow {
    from {
        padding: 0 45px;
        height: 0;
    }

    to {
        padding: 60px 45px;
        height: 1100px;
    }

}

@keyframes shrink {
    from {
        padding: 60px 45px;
        height: 1100px;
    }

    to {
        padding: 0 45px;
        height: 0;
    }

}

/* ---------- Media Queries ---------- */
@media only screen and (max-device-width: 768px) {
    .base {
        padding: 15px;
    }
}
