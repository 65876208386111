.base {
    max-width: 1000px;
    margin: 0 auto;
}

.base .why-write {
    max-width: 460px;
    background: white;
    padding: 30px;
}

.base .why-write svg {
    color: #0500FF;
    font-size: 45px;
    position: absolute;
    z-index: 1;
}

.base .why-write h2 {
    color: #0500FF;
    z-index: 2;
    position: relative;
    margin-left: 60px;
    bottom: 10px;

}

.base .why-write p {
    margin-left: 60px;
}

.base header, .base footer {
    text-align: center;
    max-width: 700px;
    margin: 90px auto 60px;
}

.base header h1 {
    color: #171572;
    font-size: 3.6rem;
    margin-bottom: 2rem;
}

.base header h2, .base footer h2 {
    color: #171572;
    font-weight: lighter;
}

.base h1 strong {
    border-bottom: 3px solid #0500FF;
}

.base .grid {
    display: flex;
    flex-wrap: wrap;

}

.base .lesson {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.base .write-step button {
    cursor: pointer;
    color: #171572;
    background: #FAFF00;
    border: none;
    padding: 0 3px;
    margin: 0;
    font-family: unset;
    font-size: unset;
    text-align: left;
    transition: 200ms;
    border-bottom: 1px solid transparent;
}

.base .write-step button:hover {
    color: #0500FF;
    background: transparent;
    border-bottom: 1px solid #0500FF;

}

.base footer {
    margin-bottom: 105px;
}

@media only screen and (max-device-width: 768px) {
    .base {
        margin: 0 15px;
    }
}
