.base {
    background-color: #171572;
}
header.App-header {
    /*overflow: auto;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 70vh;
    color: white;
    background-color: #171572;
    width: 100%;
}

header img.flyer {
    cursor: pointer;
    max-width: 420px;
    border: 3px solid transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.60);
    transition: 100ms ease;
}

header img.flyer:hover {
    border: 3px solid #0500FF;
}

.label {
    display: flex;
    align-items: center;
    color: #E32727;
    font-weight: bold;
    margin-bottom: 20px;
    transition: 300ms ease;
}

.label img {
    max-width: 40px;
    margin-right: 5px;
}



header h1 {
    font-size: 4rem;
    margin: 0;
}
header h2 {
    font-size: 2.2rem;
    font-weight: lighter;
    margin: 0;
}

header .text {
    box-sizing: border-box;
    margin-left: 50px;
    max-width: 100%;
}


header .text p {
    font-size: 1.8rem;
    margin: 5px 0;
}

header .text a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #FAFF00;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    background: transparent;
    padding: 12px 24px;
    margin-top: 30px;
    border: 2px solid #FAFF00;
    border-radius: 30px;
    transition: 100ms ease;
}

header .text a:hover {
    text-decoration: none;
    color: #0500FF;
    border: 2px solid #FAFF00;
    background: #FAFF00;
}

header .text a:active {
    border: 2px solid #E32727;
}


/* ---------- Media Queries ---------- */
@media only screen and (max-device-width: 768px) {

    header {
        padding: 30px;
    }

    header img.flyer {
        width: 100%;
        margin-bottom: 30px;
    }

    header .text {
        margin-top: 30px;
        margin-left: 0;
        margin-bottom: 30px;
    }
    header a {
        margin-bottom: 30px;
    }
}
