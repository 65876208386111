.base {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background: #313b91;
    border-radius: 10px;
    padding: 15px 30px;
    transition: 250ms ease;
    flex: 33% 1;
    min-width: 400px;
    margin: 15px 0;
}

.base:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    transform: translateY(-5px) scale(1.01, 1.01);
}

.base .id {
    font-size: 7rem;
    font-weight: bold;
    margin-right: 30px;
    opacity: 0.4;
}

.base h1 {
    font-size: 2.0rem;
    margin: 0;
}

.base h2 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: normal;
}

.base p {
    font-size: 1.8rem;
    margin: 0;

}

.base a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #FAFF00;
    font-weight: bold;
    padding: 12px 24px;   
    padding: 6px 12px;
    border-radius: 8px;
    text-decoration: none;
    transition: 200ms;
    border: 2px solid #FAFF00;
    border-radius: 30px;
    transition: 100ms ease;

}

.base a:hover {
    text-decoration: none;
    color: #0500FF;
    border: 2px solid #FAFF00;
    background: #FAFF00;
    
}

@media only screen and (max-device-width: 425px) {
    .base {
        min-width: unset;
    }
}

@media (max-width: 590px) {

    .base {
        flex-direction: column;
    }
    .base a {
        margin-top: 6px;
    }
}
