.base {
    color: white;
    background: #171572;
    text-align: center;
    padding: 75px;
}

.base h2 {
    font-weight: lighter;
    margin-bottom: 10px;
}

.base h1 {
    margin-top: 0;
}

.base .logo {
    cursor: pointer;
    opacity: 0.4;
    margin-top: 15px;
    max-width: 200px;
    transition: 300ms;

}

.base .logo:hover {
    opacity: 1;
    box-shadow: 0 0 5px black;
    background: rgba(5, 0, 255, 0.4);
    transform: translateY(-2px) scale(1.01, 1.01);
}

.base .logo:active {
    background: transparent;
    transition: 0ms;
}
